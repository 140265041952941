<template>
  <VueDatePicker
    ref="datepicker"
    v-model="datetime"
    :locale="ruLocale.code"
    :format="props.format"
    :min-date="new Date()"
    :time-picker-inline="true"
    :minutes-increment="15"
    text-input
    @update:model-value="select"
  >
    <template #trigger>
      <input
        v-maska
        :data-maska="format.replace(/\w/g, '#')"
        data-maska-eager="1"
        type="text"
        class="form-control"
        :class="inputClass"
        :value="getDate"
        @change="change"
      >
    </template>
    <template #calendar-header="{ index, day }">
      <div :style="index === 5 || index === 6 ? 'color: #d32d30' : ''">
        {{ day }}
      </div>
    </template>
    <template #action-row="{ internalModelValue, selectDate }">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="heading-text-2">{{ formatDate(internalModelValue) }}</span>
        <button
          class="btn btn-sm btn-primary"
          type="button"
          @click="selectDate"
        >
          Принять
        </button>
      </div>
    </template>
  </VueDatePicker>
</template>

<script setup lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker';
import type { DatePickerInstance } from '@vuepic/vue-datepicker';
import { format as fnsFormat, parse } from 'date-fns';
import ruLocale from 'date-fns/locale/ru/index.js';
import type { PropType } from "vue";

const props = defineProps({
  modelValue: { type: Date as PropType<Date|null>, default: new Date() },
  format: { type: String, default: 'dd.MM.yyyy HH:mm' },
  inputClass: { type: String, default: undefined }
});
const emits = defineEmits(['update:modelValue']);

//-----STATE-----\\
const datetime = ref(props.modelValue);
const datepicker = ref<DatePickerInstance>(null);

//-----COMPUTED-----\\
const getDate = computed<string|undefined>(() => {
  if (datetime.value === null) {
    return undefined;
  }
  return formatDate(datetime.value);
});

//-----METHODS-----\\
function formatDate(date: Date|null): string {
  if (date === null) {
    return '';
  }
  return fnsFormat(date, props.format);
}
function change(obj) {
  const date = parse(obj.target.value, props.format, new Date());
  if (isNaN(date)) {
    return;
  }

  datetime.value = date;
  emits('update:modelValue', date);
  datepicker.value!.closeMenu();
}
function select(date: Date) {
  emits('update:modelValue', date);
}
</script>

<style lang="scss">
@import '@vuepic/vue-datepicker/dist/main.css';
</style>
